
@media only screen and (min-width: 1930px) {
    .modal-height {
        max-height: calc(
            100vh - 80px
          ) !important; 
        height: calc(
            100vh - 80px
          ) !important; 
    } 
}

@media only screen and (min-width: 1530px) {
    .modal-width {
        max-width: 900px !important;
    } 
}