
.cell-description { 
  max-width: 320px;
  // overflow-wrap: break-word;
  // white-space: wrap;
  overflow-wrap: break-all;
}
.cell-status {
  min-width: 110px;

}
@media screen and (max-width: 576px) {
  .marginTop {
    margin-top: 16px !important; //mt-3 = 16px
   }
}