/* @import '../../../node_modules/@syncfusion/ej2-base/styles/tailwind.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/tailwind.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/tailwind.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/tailwind.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/tailwind.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/tailwind.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/tailwind.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/tailwind.css';
@import "../../../node_modules/@syncfusion/ej2-react-grids/styles/tailwind.css"; */

/* .keydescription {
    padding-left: 20px;
  }
  .keys {
    display: inline-block;
    width: 230px;    
  } */
  
  .e-grid {   
    font-family: Open Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
    padding: 0.75rem !important;
    color: #edf2f9 !important;
    font-size: .8333333333rem!important;
}
/* .e-grid .e-table {
  table-layout: auto !important;
} */
.e-grid .e-gridheader {
  background-color: #edf2f9;
  font-weight: bold !important;
}
.e-grid .e-columnheader .e-headercell {
  background-color: #edf2f9;
}
.e-grid .e-headercelldiv{ 
  font-weight: bold !important;
}
.e-grid .e-content td.e-selected span.e-grid {
  background-color: #deecf9 !important;
}
.e-grid .e-field .e-input .e-defaultcell {
  background: #deecf9 !important;
}
.e-grid .e-input-group .e-control-wrapper .e-valid-input ::selection {
  background: #deecf9 !important;
}
/* .e-grid .e-link .e-numericitem .e-spacing .e-currentitem .e-active{
  background-color: #deecf9;
  color: #fff;
} */
.e-pager .e-currentitem, .e-pager .e-currentitem:hover 
{
  background-color: #2c7be5 !important;
  color: #fff;
  opacity: 1;
}