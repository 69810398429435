
.validation-error {
  border: #e63757 solid 1px;
}
.validation-error-msg {
  color: #e63757; font-size:0.75rem; margin-top: 0.25rem;
}

@media screen and (max-width: 576px) {
  .marginTop {
    margin-top: 16px !important; //mt-3 = 16px
   }
}