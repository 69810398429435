  
  
  .search-box {
    width: 100% !important;
  }
  .button-width {
    padding: 0.3125rem 1rem;
  }
  // .table-fixed{
  //   width: 100%;
  //   tbody{
  //     max-height: 700px; 
  //     min-height: 300px;
  //   //    calc(
  //   //     100vh-150px
  //   //   );
  //     overflow-y:auto;
  //     width: 100%
  //     }
  //   thead,tbody,tr,td,th{
  //     display:block;
  //   }
  
  // }
  