/* .tab-dashboard {
    margin: 0 -15px; 
  } */

  @media only screen and (min-width: 700px) and (max-width: 1000px) {
    .tab-responsive-dashboard .tab-container {
      width: 100%;
      height: calc(
        100vh - 175px
      ); /* 100 vh - 119px(50px topbar, 15px margin, 39px footer and 15px bottom margin) */
    }
  }
  @media only screen and (max-width: 700px) {
    .tab-responsive-dashboard .tab-container {
      width: 100%;
      height: calc(
        100vh - 265px
      ); /* 100 vh - 119px(50px topbar, 15px margin, 39px footer and 15px bottom margin) */
    }
  }
  @media only screen and (min-width: 1010px) {
  .tab-responsive-dashboard .tab-container {
    width: 100%;
    height: calc(
      100vh - 200px
    ); /* 100 vh - 119px(50px topbar, 15px margin, 39px footer and 15px bottom margin) */
  }
}
  
  .wrapper .wrapper-content .tab-dashboard .tab-container {
    height: calc(100vh - 164px);
  }
  
  .tab-dashboard .tab-container iframe {
    border: 0;
    width: 100% !important;
    height: var(--tabDashboardHeight) !important;
     /*min-height: 600px !important; */
  }
  .tab-dashboard.hide {
    display: none;
  }
  
  /* .tab-dashboard.hide {
    display: none; 
  }*/
  /* calc(100vh - 119px) 100 vh - 119px(50px topbar, 15px margin, 39px footer and 15px bottom margin) */
  /* .tab-dashboard .tab-container iframe {
    border: 0;
    width: 100% !important;
    height: 95% !important;
    min-height: 600px !important; 
  }*/
  /*.tabWrap {
    height: 90%;
  }
  .tabWrapXL {
    height: 90%;
  }
  .tabWrapLG {
    height: 90%;
  }
  .tabWrapMD {
    height: 90%;
  }
  .tabWrapSM {
    height: 90%;
  }
  .tabWrapXS {
    height: 89%;
  }
  .vizWrap {
    height: 98%;
  } */
  