.report-status-pill {
    min-width: 120px;
    border-radius: 3px;
    padding: 6px 12px;
    text-align: center;
}

.report-status-pill-draft {
    border: 1px solid #e7eaec;
}

.report-status-pill-submitted {
    border: 1px solid #f8ac59;
    background-color: #f8ac59;
    color: #fff;
}

.report-status-pill-approved {
    border: 1px solid #1ab394;
    background-color: #1ab394;
    color: #fff;
}

.report-status-pill-rejected {
    border: 1px solid #ed5565;
    background-color: #ed5565;
    color: #fff;
}
