/* @import "/node_modules/@syncfusion/ej2-react-filemanager/styles/material.css"; */
/* @media (min-width: 1600px) {
    .windowHeight {
        height: 650px !important;
        margin-bottom: 35px;
    }
} */

/* @media (min-width: 1200px) { */
    .windowHeight {
        width: 100%;
        height: calc(
          100vh - 175px
        ) !important; 
    }
/* } */

@media only screen and (min-width: 700px) and (max-width: 1000px) {
    .windowHeight {
        width: 100%;
        height: calc(
          100vh - 150px
        ) !important; 
    }
}