@import "/node_modules/@syncfusion/ej2-base/styles/material.css";
@import "/node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "/node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "/node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "/node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "/node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "/node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "/node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "/node_modules/@syncfusion/ej2-querybuilder/styles/material.css";



 @media only screen and (min-width: 1600px) {
    .e-rule-filter {
        width: 14% !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .e-rule-filter {
        width: 20% !important;
    }
} 

.search-box {
    width: 100% !important;
  }
  .button-width {
    padding: 0.3125rem 1rem;
  }