.reportStatusButton {
    pointer-events: none; 
}

.reportStatusMargin {
    margin-top: 28px;
}

.reportMarginLeft {
    margin-left: 9px !important;
}

.reportMarginRight {
    margin-right: 9px !important;
}
.icon-star {
    color: #f8ac59;    
}
.mobile-display {
  margin-left: -20px;
}

.bold-mobile-button-font {
  font-size: small !important;
}

@media only screen and (min-width: 700px) and (max-width: 1000px) {
  .e-reportviewer {
    width: 100%;
    height: calc(
      100vh - 165px
    ) !important; 
  }
}
@media only screen and (min-width: 1010px) {
  .e-reportviewer {
    width: 100%;
    height: calc(
      100vh - 172px
    ) !important; 
  }
}