
@media screen and (min-width: 992px) {
.navbar-vertical-collapsed-hover .navbar-vertical.navbar-expand-lg 
.vertical-menu-title
{
    display: inline !important;    
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg 
.vertical-menu-title
{
    display: none;
}
}